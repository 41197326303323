import React from 'react';

import ProductWithoutImage from '../../../../assets/ProductWithoutImage.png';
import useStyles from './styles';

function Item({ item, handleClickItem }) {
  const classes = useStyles();

  return (
    <button
      type="button"
      className={classes.root}
      onClick={() => handleClickItem(item)}
    >
      <div className={classes.fields}>
        <span className={classes.name}>{item.name.toLowerCase()}</span>
        <span className={classes.description}>{item.description}</span>
        <span className={classes.price}>{item.formattedPrice}</span>
      </div>
      <div className={classes.media}>
        <img
          className={classes.img}
          src={item.url ? item.url : ProductWithoutImage}
          alt={item.name}
        />
      </div>
    </button>
  );
}

export default Item;
