import React from 'react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import useStyles from './styles';

function Header({ close }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <KeyboardBackspaceIcon onClick={close} fontSize="large" />
      <span>Detalhe</span>
    </div>
  );
}

export default Header;
