import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import edit from '../../../assets/edit.svg';
import deletar from '../../../assets/delete.svg';
import ProductWithoutImage from '../../../assets/ProductWithoutImage.png';

import { formatPrice } from '../../../utils/format';

import useStyles from './styles';
import { selected } from '../../../store/modules/item/actions';
import { removeItem } from '../../../store/modules/order/actions';

function Items() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalMenu, setModalMenu] = useState();
  const [item, setItem] = useState({});

  const selecteds = useCallback(complements => {
    const data = [];
    complements.forEach(complement => {
      complement.items.forEach(complementItem => {
        if (complementItem.amount >= 1) {
          data.push(complementItem);
        }
      });
    });

    return data;
  }, []);

  const items = useSelector(state => {
    const { order } = state.order;
    if (order && order.items) {
      return order.items.map(orderItem => {
        return {
          ...orderItem,
          formattedSubtotal: formatPrice(orderItem.subtotal),
          selectedComplements: selecteds(orderItem.complements),
        };
      });
    }

    return [];
  });

  const handleMenu = useCallback(product => {
    setItem(product);
    setModalMenu(true);
  }, []);

  const handleItem = useCallback(
    product => {
      setModalMenu(false);

      dispatch(selected(product));
    },
    [dispatch]
  );

  const handleRemoveItem = useCallback(
    product => {
      setModalMenu(false);

      dispatch(removeItem(product.key));
    },
    [dispatch]
  );

  return items ? (
    <>
      <div className={classes.root}>
        {items.map(product => (
          <div
            key={JSON.stringify(product.key + product.product_id)}
            className={classes.item}
          >
            <div className={classes.body} onClick={() => handleItem(product)}>
              <div className={classes.defaults}>
                <div className={classes.media}>
                  <img
                    className={classes.img}
                    src={product.url ? product.url : ProductWithoutImage}
                    alt={product.name}
                  />
                </div>
                <div className={classes.data}>
                  <span className={classes.span}>{`${product.amount}x`}</span>
                  <div className={classes.fields}>
                    <span className={classes.span}>{product.name}</span>
                  </div>
                  <div className={classes.price}>
                    <span>{product.formattedSubtotal}</span>
                  </div>
                </div>
              </div>

              {product.selectedComplements.length >= 1 && (
                <ul className={classes.complements}>
                  {product.selectedComplements &&
                    product.selectedComplements.map(complement => (
                      <li
                        key={JSON.stringify(product.key + complement.item_id)}
                      >
                        {`${complement.amount}x ${complement.name}`}
                      </li>
                    ))}
                </ul>
              )}

              {product.comment !== '' && (
                <div className={classes.comment}>
                  <SpeakerNotesIcon className={classes.icon} />
                  <span>{product.comment}</span>
                </div>
              )}
            </div>
            <div className={classes.menu}>
              <IconButton onClick={() => handleMenu(product)}>
                <MoreIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>

      <Drawer
        anchor="bottom"
        open={modalMenu}
        onClose={() => setModalMenu(false)}
      >
        <div className={classes.listSettings}>
          {item && (
            <>
              <h1>{item.name}</h1>
              <ul className={classes.ul}>
                <div
                  className={classes.btnOptions}
                  onClick={() => handleItem(item)}
                >
                  <span>editar</span>
                  <img src={edit} alt="editar" />
                </div>
                <div
                  className={classes.btnOptions}
                  onClick={() => handleRemoveItem(item)}
                >
                  <span>remover</span>
                  <img src={deletar} alt="remover" />
                </div>
              </ul>
            </>
          )}
        </div>
      </Drawer>
    </>
  ) : null;
}

export default Items;
