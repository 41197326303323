import produce from 'immer';

const INITIAL_STATE = {
  product: null,
  modalItem: false,
};

export default function item(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@item/SELECTED': {
        const { product } = action.payload;

        if (product) {
          draft.product = {
            ...product,
            key: product.key || new Date().getTime(),
            amount: product.amount || 1,
            comment: product.comment || '',
            complements: product.complements
              ? product.complements.map(complement => {
                  return {
                    ...complement,
                    selected: complement.selected || 0,
                    items: complement.items.map(obj => {
                      return {
                        ...obj,
                        amount: obj.amount || 0,
                      };
                    }),
                  };
                })
              : [],
          };
          draft.modalItem = true;
        }
        break;
      }

      case '@item/COMPLEMENT_ADD': {
        const { product } = action.payload;

        if (product) {
          const comple = draft.product.complements.find(
            f => f.complement_id === product.complement_id
          );

          if (comple.selected === comple.max) break;

          draft.product = {
            ...draft.product,
            complements: draft.product.complements.map(complement => {
              return {
                ...complement,
                selected:
                  complement.complement_id === product.complement_id
                    ? complement.selected + 1
                    : complement.selected,
                items: complement.items.map(obj => {
                  return {
                    ...obj,
                    amount:
                      obj.item_id === product.item_id
                        ? obj.amount + 1
                        : obj.amount,
                  };
                }),
              };
            }),
          };
        }
        break;
      }

      case '@item/COMPLEMENT_REMOVE': {
        const { product } = action.payload;

        if (product) {
          const comple = draft.product.complements.find(
            f => f.complement_id === product.complement_id
          );

          if (comple.selected <= 0) break;

          draft.product = {
            ...draft.product,
            complements: draft.product.complements.map(complement => {
              return {
                ...complement,
                selected:
                  complement.complement_id === product.complement_id
                    ? complement.selected - 1
                    : complement.selected,
                items: complement.items.map(obj => {
                  return {
                    ...obj,
                    amount:
                      obj.item_id === product.item_id
                        ? obj.amount - 1
                        : obj.amount,
                  };
                }),
              };
            }),
          };
        }
        break;
      }

      case '@item/CHANGE_AMOUNT': {
        const { amount = 1 } = action.payload;
        if (amount <= 0) break;
        draft.product = { ...draft.product, amount };
        break;
      }

      case '@item/SET_COMMENT': {
        const { comment = null } = action.payload;
        draft.product = { ...draft.product, comment };
        break;
      }

      case '@item/UNSET_COMMENT': {
        draft.product = { ...draft.product, comment: null };
        break;
      }

      case '@item/CLOSE': {
        draft.modalItem = false;
        break;
      }

      case '@starting': {
        draft.loading = false;
        draft.modalItem = false;
        break;
      }

      default:
    }
  });
}
