import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    margin: 0,
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 270px)',
    overflowY: 'auto',
    background: '#fff',
  },
  empty: {
    margin: 0,
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
    height: 'calc(100vh - 118px)',
    overflowY: 'auto',
    background: '#fff',

    '& img': {
      width: '256px',
      maxWidth: '256px',
    },

    '& strong': {
      fontSize: '1rem',
      color: '#333',
    },
  },
  send: {
    minHeight: '152px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '1rem',
    padding: '1rem',
    background: '#fff',

    '& > button': {
      width: '100%',
      height: '3rem',
      borderRadius: '0.5rem',
      background: '#298e4f',
      color: '#fff',
      fontSize: '0.875rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
    },
  },
  option: {
    textTransform: 'capitalize',
  },
});

export default useStyles;
