import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selected } from '../../store/modules/item/actions';
import Category from './Category';
import Header from './Header';

import useStyles from './styles';

function Categories() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { categories: list = [] } = useSelector(state => state.auth);

  const handleClickItem = useCallback(
    product => {
      dispatch(selected(product));
    },
    [dispatch]
  );

  return (
    <div className={classes.root}>
      <Header />

      <div className={classes.list}>
        {list.map(category => (
          <Category
            key={category.category_id}
            category={category}
            handleClickItem={handleClickItem}
          />
        ))}
      </div>
    </div>
  );
}

export default Categories;
