import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },
  list: {
    margin: '0',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 118px)',
    overflowY: 'auto',
    background: '#fff',
  },
});

export default useStyles;
