import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import useStyles from './styles';

function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MenuIcon fontSize="large" />
      <span>Categorias</span>
    </div>
  );
}

export default Header;
