import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  company: null,
  categories: [],
  payments: [],
  users: [],
  user: null,
  signed: false,
  menuModal: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_SUCCESS': {
        draft.company = action.payload.company;
        draft.categories = action.payload.categories;
        draft.users = action.payload.users;
        const payments = [
          { id: 1, code: 1, title: 'dinheiro', card: 0 },
          { id: 2, code: 2, title: 'pix', card: 0 },
        ];
        action.payload.cards.forEach(card => {
          payments.push({
            id: payments.length + 1,
            code: 3,
            title: card.name,
            card: card.card_id,
          });
        });
        draft.payments = payments;
        draft.user = action.payload.user;
        draft.signed = true;
        draft.loading = false;
        break;
      }

      case '@auth/MENU': {
        draft.menuModal = !draft.menuModal;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.loading = false;
        draft.company = null;
        draft.categories = [];
        draft.payments = [];
        draft.user = null;
        draft.signed = false;
        draft.menuModal = false;
        break;
      }

      case '@starting': {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
