export function signRequest(id, password) {
  return {
    type: '@auth/SIGN_REQUEST',
    payload: { id, password },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signSuccess(company, categories, cards, user, users) {
  return {
    type: '@auth/SIGN_SUCCESS',
    payload: { company, categories, cards, user, users },
  };
}

export function menu() {
  return {
    type: '@auth/MENU',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function starting() {
  return {
    type: '@starting',
  };
}
