import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: '0px',
    padding: '1rem',
    borderBottom: '1px solid rgba(149, 149, 149, 0.4)',
  },
  subtotal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.875rem',
    fontWeight: '300',
    marginBottom: '10px',
  },
  delivery: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.875rem',
    fontWeight: '300',
    marginBottom: '10px',
  },
  total: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.975rem',
    fontWeight: '500',
  },
}));

export default useStyles;
