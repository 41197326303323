import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import emptyCart from '../../assets/empty-cart.png';
import Header from './Header';
import Items from './Items';
import Send from './Send';
import useStyles from './styles';
import Totals from './Totals';

function Cart() {
  const classes = useStyles();
  const [send, setSend] = useState(false);
  const { user, users } = useSelector(state => state.auth);
  const [userId, setUserId] = useState(user.id);
  const {
    order: { items = [] },
  } = useSelector(state => state.order);

  return (
    <div className={classes.root}>
      <Header />
      {items.length >= 1 && (
        <>
          <div className={classes.list}>
            <Items />
            <Totals />
          </div>
          <div className={classes.send}>
            {user.is_waiter === 'S' && (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="vendedor-label">Vendedor</InputLabel>
                <Select
                  labelId="vendedor-label"
                  id="vendedor-select"
                  value={userId}
                  onChange={e => setUserId(e.target.value)}
                  label="Vendedor"
                  disabled={
                    user.company_id !== '276' && user.company_id !== '231'
                  }
                  className={classes.option}
                >
                  {users.map(item => (
                    <MenuItem
                      key={item.user_id}
                      className={classes.option}
                      value={item.user_id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <button type="button" onClick={() => setSend(true)}>
              {user.is_waiter === 'S' && <span>enviar pedido</span>}
              {user.is_operator === 'S' && <span>receber pedido</span>}
            </button>
          </div>
        </>
      )}
      {items.length === 0 && (
        <div className={classes.empty}>
          <img src={emptyCart} alt="Carrinho vazio" />
          <strong>Carrinho vazio</strong>
        </div>
      )}
      <Send send={send} setSend={setSend} user={{ ...user, id: userId }} />
    </div>
  );
}

export default Cart;
