export function addItem(item) {
  return {
    type: '@order/ADD_ITEM',
    payload: { item },
  };
}

export function removeItem(key) {
  return {
    type: '@order/REMOVE_ITEM',
    payload: { key },
  };
}

export function orderRequest(order) {
  return {
    type: '@order/ORDER_REQUEST',
    payload: { order },
  };
}

export function orderFailure() {
  return {
    type: '@order/ORDER_FAILURE',
  };
}

export function orderSuccess() {
  return {
    type: '@order/ORDER_SUCCESS',
  };
}

export function saleRequest(sale) {
  return {
    type: '@order/SALE_REQUEST',
    payload: { sale },
  };
}

export function saleFailure() {
  return {
    type: '@order/SALE_FAILURE',
  };
}

export function saleSuccess() {
  return {
    type: '@order/SALE_SUCCESS',
  };
}
