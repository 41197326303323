import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Drawer,
  FormControlLabel,
  Switch,
  TextField,
} from '@material-ui/core';

import md5 from 'md5';
import { cancelRequest } from '../../../store/modules/data/actions';

import useStyles from './styles';

function Cancellation({ cancelComanda, setCancelComanda }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [number, setNumber] = useState();
  const [comment, setComment] = useState('');
  const [client, setClient] = useState(false);

  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    if (cancelComanda) {
      const { numero } = cancelComanda;
      setNumber(numero);
      setComment('');
      setClient(false);
    }
  }, [cancelComanda]);

  const handleConfirm = useCallback(
    ({ number: numero, comment: motivo, client: cliente }) => {
      const { id: vendedor_id } = user;
      const key = md5(new Date().getTime()).toUpperCase();

      dispatch(
        cancelRequest({
          numero,
          operador_id: 0,
          vendedor_id,
          motivo,
          cliente: cliente ? 'S' : 'N',
          key,
        })
      );
    },
    [user, dispatch]
  );

  return (
    <Drawer
      anchor="bottom"
      open={!!cancelComanda}
      onClose={() => setCancelComanda(null)}
    >
      <div className={classes.root}>
        <h1>cancelar</h1>
        <TextField
          error={comment.length < 5}
          label="Motivo"
          multiline
          rows={3}
          variant="outlined"
          value={comment}
          onChange={event => {
            if (event.target.value.length <= 30) setComment(event.target.value);
          }}
        />

        <div className={classes.switch}>
          <FormControlLabel
            control={
              <Switch checked={client} onChange={() => setClient(!client)} />
            }
            label="Cliente"
          />
        </div>

        <Button
          variant="contained"
          className={classes.button}
          onClick={() => {
            handleConfirm({ number, comment, client });
          }}
          disabled={comment.length < 5}
        >
          confirmar
        </Button>
      </div>
    </Drawer>
  );
}

export default Cancellation;
