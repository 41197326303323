import React from 'react';
import { useSelector } from 'react-redux';
import { formatPrice } from '../../../utils/format';
import useStyles from './styles';

function Totals() {
  const classes = useStyles();

  const { formattedSubtotal } = useSelector(state => {
    const { order } = state.order;

    return {
      formattedSubtotal: formatPrice(
        order.items.reduce((total, item) => {
          return total + item.subtotal;
        }, 0)
      ),
    };
  });

  return (
    <ul className={classes.root}>
      <li className={classes.subtotal}>
        <span>Subtotal</span>
        <span>{formattedSubtotal}</span>
      </li>
    </ul>
  );
}

export default Totals;
