import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    background: '#fff',
    '& svg': {
      color: '#011133',
    },
  },
  infoComand: {
    minHeight: '60px',
    margin: 0,
    padding: '0 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',

    fontWeight: 600,
    borderBottom: '3px solid #e0e0e0',
  },
  ul: {
    padding: '1rem 1rem 0',
    margin: '0px',
    height: 'calc(100vh - 242px)',
    overflow: 'auto',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '0 0 1rem',
  },
  fields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-betweeen',
    flex: 1,
  },
  info: {
    marginLeft: '1rem',
    fontWeight: 500,

    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',

    '& h1': {
      fontSize: '1rem',
      margin: 0,
      padding: 0,
      fontWeight: 600,
    },
    '& span': {
      textTransform: 'capitalize',
    },
    flex: 1,
  },
  price: {
    '& span': {
      textAlign: 'right',
      fontWeight: 600,
      fontSize: '0.875rem',
    },
  },
}));

export default useStyles;
