import React, { useCallback, useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { MoreVert, SyncAlt, DeleteOutline } from '@material-ui/icons';
import { comandaItemsRequest } from '../../../store/modules/data/actions';
import useStyles from './styles';
import Transfer from './Transfer';
import Cancellation from './Cancellation';

function Items() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [product, setProduct] = useState(null);
  const [transferItem, setTransferItem] = useState(null);
  const [cancelItem, setCancelItem] = useState(null);

  const { user } = useSelector(state => state.auth);
  const { loading, comanda } = useSelector(state => state.data);

  useEffect(() => {
    if (!loading && comanda && !comanda.items)
      dispatch(comandaItemsRequest(comanda));
  }, [loading, comanda, dispatch]);

  const handleTransfer = useCallback(data => {
    setProduct(null);
    setTransferItem(data);
  }, []);

  const handleCancellation = useCallback(data => {
    setProduct(null);
    setCancelItem(data);
  }, []);

  return (
    <>
      <div className={classes.root}>
        <ul className={classes.list}>
          {comanda &&
            comanda.items &&
            comanda.items.map(item => (
              <li key={item.item_id}>
                <div className={classes.item}>
                  <span className={classes.amount}>{item.quantidade}</span>
                  <span className={classes.name}>{item.item}</span>
                  {comanda &&
                    comanda.situacao === 'A' &&
                    user &&
                    (user.transfer === 'S' || user.cancelation === 'S') && (
                      <MoreVert
                        fontSize="medium"
                        onClick={() => setProduct(item)}
                      />
                    )}
                </div>
              </li>
            ))}
        </ul>
      </div>

      <Drawer anchor="bottom" open={!!product} onClose={() => setProduct(null)}>
        <div className={classes.itemOptions}>
          {product && (
            <>
              <h1>{product.item}</h1>
              <ul>
                {user && user.transfer === 'S' && (
                  <li>
                    <a
                      href
                      className={classes.option}
                      onClick={() => handleTransfer(product)}
                    >
                      <span>transferir item</span>
                      <SyncAlt />
                    </a>
                  </li>
                )}

                {user && user.cancelation === 'S' && (
                  <li>
                    <a
                      href
                      className={classes.option}
                      onClick={() => handleCancellation(product)}
                    >
                      <span>remover item</span>
                      <DeleteOutline />
                    </a>
                  </li>
                )}
              </ul>
            </>
          )}
        </div>
      </Drawer>

      <Transfer transferItem={transferItem} setTransferItem={setTransferItem} />

      <Cancellation cancelItem={cancelItem} setCancelItem={setCancelItem} />
    </>
  );
}

export default Items;
