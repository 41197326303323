import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Item from '../Categories/Category/Item';
import Header from './Header';

import useStyles from './styles';
import { selected } from '../../store/modules/item/actions';
import { changeSearch } from '../../store/modules/data/actions';
import { formatDescription, formatPrice } from '../../utils/format';

function Search() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { categories: list = [] } = useSelector(state => state.auth);
  const { search } = useSelector(state => state.data);

  let products = [];

  if (search.trim().length >= 1) {
    products = list
      .map(category => category.products)
      .reduce((acc, cur) => [...acc, ...cur], [])
      .filter(product =>
        product.codename.toLowerCase().includes(search.toLocaleLowerCase())
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(product => {
        return {
          ...product,
          description: formatDescription(product.description),
          descriptionFull: product.description,
          formattedPrice: formatPrice(product.price),
        };
      });
  }

  const handleClickItem = useCallback(
    product => {
      dispatch(selected(product));
    },
    [dispatch]
  );

  const handleSearch = useCallback(
    e => {
      dispatch(changeSearch(e.target.value));
    },
    [dispatch]
  );

  const handleClear = useCallback(() => {
    dispatch(changeSearch(''));
    const input = document.getElementById('search');
    if (input) input.focus();
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.search}>
        <input
          id="search"
          type="text"
          value={search}
          onChange={handleSearch}
          autoComplete="off"
        />
        {search.length >= 1 && <CloseIcon onClick={handleClear} />}
      </div>
      <ul className={classes.list}>
        {products.map(product => (
          <Item
            key={product.product_id}
            item={product}
            handleClickItem={handleClickItem}
          />
        ))}
      </ul>
    </div>
  );
}

export default Search;
