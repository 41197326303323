import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    minHeight: '134px',
    padding: '0 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#011133',

    '& svg ': {
      color: '#fff',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',

    '& span': {
      margin: 0,
      padding: 0,
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#fff',

      fontSize: '18px',
    },
    '& strong': {
      fontSize: '12px',
      fontWeight: 400,
      color: '#fff',
    },
  },
  infosHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginRight: '1rem',
    height: '70px',
    width: '70px',
    borderRadius: '50%',
    border: '3px solid #fff',
  },
});

export default useStyles;
