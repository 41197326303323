/* eslint-disable react/prop-types */
import React from 'react';

import useStyles from './styles';

function Nunber({ comanda }) {
  const background = () => {
    if (comanda.situacao === 'F')
      return {
        background: '#ff0000',
        color: '#fff',
      };

    return comanda.minutos <= 15
      ? {
          background: '#298e4f',
          color: '#fff',
        }
      : {
          background: '#011133',
          color: '#fff',
        };
  };

  const classes = useStyles({ comanda: background() });

  return (
    <div className={classes.root}>
      <span>{comanda.numero}</span>
    </div>
  );
}

export default Nunber;
