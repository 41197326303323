import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },
  keyboard: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transform: 'translateY(-15px)',
    borderRadius: '1rem 1rem 0 0',
    background: '#fff',
    zIndex: 1000,
  },
  row: {
    display: 'flex',
  },
  number: {
    minWidth: '75px',
    height: '75px',
    margin: '5px',
    padding: '10px',
    borderRadius: '1rem',
    background: '#fff',
    border: '3px solid #c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: '#010D1F',
    fontSize: '26px',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  cancel: {
    minWidth: '75px',
    height: '75px',
    margin: '5px',
    padding: 0,
    borderRadius: '1rem',
    background: '#ff0000',
    border: '3px solid #c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: '#fff',
    fontSize: '10px',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  enter: {
    minWidth: '75px',
    height: '75px',
    margin: '5px',
    padding: 0,
    borderRadius: '20px',
    background: '#298e4f',
    border: '3px solid #c4c4c4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    color: '#fff',
    fontSize: '10px',
    textTransform: 'uppercase',
    fontWeight: 600,
  },
  comanda: {
    padding: '1rem 1rem 2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: 600,
    borderBottom: '3px solid #eee',
    background: '#011133',
    color: '#fff',
  },
  payment: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '0 1rem 1rem 1rem',
    background: '#fff',
  },
  header: {
    height: '3rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '0.875rem',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  options: {
    height: 'calc(70vh - 6rem)',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    overflowY: 'scroll',
  },
  actions: {
    height: '3rem',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',

    '& > button': {
      width: '100%',
      height: '3rem',
      borderRadius: '0.5rem',
      background: '#298e4f',
      color: '#fff',
      fontSize: '0.875rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
    },
  },
  notSelected: {
    height: '2rem',
    minHeight: '2rem',
    borderRadius: '0.5rem',
    background: '#fff',

    fontSize: '0.775rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  selected: {
    height: '2rem',
    minHeight: '2rem',
    borderRadius: '0.25rem',
    background: '#fff',

    fontSize: '0.775rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    borderTop: '2px solid #ff0000',
    borderLeft: 0,
    borderBottom: '2px solid #ff0000',
    borderRight: 0,
    outline: 'none',
    cursor: 'pointer',
  },
}));

export default useStyles;
