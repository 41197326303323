import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },
  search: {
    position: 'relative',
    width: '100%',
    height: '80px',
    padding: '1rem',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '3px solid #e0e0e0',

    '& input': {
      width: '100%',
      height: '48px',
      padding: '1rem',
      border: '2px solid #011133',
      borderRadius: '8px',
      outline: 'none',
      fontSize: '1rem',
    },

    '& svg': {
      position: 'absolute',
      top: '50%',
      right: '1.5rem',
      transform: 'translateY(-50%)',
      cursor: 'pointer',

      '&:hover': {
        color: '#ff0000',
      },
    },
  },
  list: {
    margin: 0,
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 198px)',
    overflowY: 'auto',
    background: '#fff',
  },
});

export default useStyles;
