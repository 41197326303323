/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import logo from '../../../assets/logo.jpeg';
import { menu } from '../../../store/modules/auth/actions';
import useStyles from './styles';

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { server } = useSelector(state => state.data);

  const hanldeMenu = () => {
    dispatch(menu());
  };

  return (
    <div className={classes.root}>
      <div className={classes.infosHeader}>
        <img src={logo} alt="comanda" className={classes.logo} />
        <div className={classes.info}>
          <span>comanda</span>
          <strong>{`${server.ip} | ${server.port}`}</strong>
        </div>
      </div>
      <PermIdentityIcon onClick={hanldeMenu} fontSize="large" />
    </div>
  );
}

export default Header;
