import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/logo.jpeg';
import { signRequest } from '../../store/modules/auth/actions';

import Loading from '../../components/Loading';
import useStyles from './styles';

function Sign() {
  document
    .querySelector('meta[name="theme-color"]')
    .setAttribute('content', '#011133');

  const classes = useStyles();
  const dispatch = useDispatch();

  const [id, setId] = useState('');
  const [password, setPassword] = useState('');

  const { loading } = useSelector(state => state.auth);

  const handleOnSubmit = async e => {
    e.preventDefault();
    dispatch(signRequest(id, password));
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <img className={classes.logo} src={logo} alt="Gestor Food" />
          <div className={classes.sub}>
            <h1>Informe id e senha</h1>
            <h2>Para ter acesso a comanda</h2>
            <span />
          </div>
          <form onSubmit={handleOnSubmit} className={classes.sign}>
            <input
              className={classes.input}
              type="text"
              placeholder="Id"
              value={id}
              onChange={event => setId(event.target.value)}
            />
            <input
              className={classes.input}
              type="password"
              placeholder="Senha"
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
            <input className={classes.submit} type="submit" value="ACESSAR" />
          </form>
        </div>
      </div>

      <Loading loading={loading} />
    </>
  );
}

export default Sign;
