import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './styles';

function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SearchIcon fontSize="large" />
      <span>Consulta</span>
    </div>
  );
}

export default Header;
