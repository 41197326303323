import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '../../../services/history';
import { enviarPedido, enviarVenda } from '../../../services/api';

import {
  orderFailure,
  orderSuccess,
  saleFailure,
  saleSuccess,
} from './actions';

function included() {
  toast.success('O item foi adicionado com sucesso');
  history.push('/');
}

function removed() {
  toast.success('O item foi removido com sucesso');
}

function* send({ payload }) {
  try {
    const { order } = payload;
    const response = yield call(enviarPedido, order);
    const data = response;
    if (data && data.success) {
      yield put(orderSuccess());
      toast.success('O seu pedido foi enviado com sucesso');
    } else if (data && !data.success) {
      yield put(orderFailure());
      toast.error(data.message);
    } else {
      yield put(orderFailure());
      toast.error('Houve um erro inesperado. Tente novamente');
    }
  } catch (err) {
    yield put(orderFailure());
    toast.error('Houve uma falha ao tentar enviar os dados. Tente novamente');
  }
}

function* sale({ payload }) {
  try {
    const { sale: venda } = payload;
    const response = yield call(enviarVenda, venda);
    const data = response;
    if (data && data.success) {
      yield put(saleSuccess());
      toast.success('A venda foi realizada com sucesso');
    } else if (data && !data.success) {
      yield put(saleFailure());
      toast.error(data.message);
    } else {
      yield put(saleFailure());
      toast.error('Houve um erro inesperado. Tente novamente');
    }
  } catch (err) {
    yield put(saleFailure());
    toast.error('Houve uma falha ao tentar enviar os dados. Tente novamente');
  }
}

export default all([
  takeLatest('@order/ADD_ITEM', included),
  takeLatest('@order/REMOVE_ITEM', removed),
  takeLatest('@order/ORDER_REQUEST', send),
  takeLatest('@order/SALE_REQUEST', sale),
]);
