import { makeStyles } from '@material-ui/core/styles';

const height = window.screen.height / 3;

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100vh',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 153px)',
    overflowY: 'auto',
  },
  media: {
    width: '100%',
    height: `${height}px`,
    padding: '1rem',
    borderRadius: '4px',
  },
  img: {
    width: '100%',
    height: '100%',
    borderRadius: '6px',
    border: '3px solid rgba(149, 149, 149, 0.4)',
    objectFit: 'cover',
  },
  fields: {
    margin: '0 1rem 1rem',
    display: 'flex',
    flexDirection: 'column',
  },
  fieldName: {
    fontSize: '18px',
    fontWeight: '500',
  },
  fieldDescription: {
    fontSize: '14px',
    fontWeight: '400',
    margin: '10px 0',
  },
  fieldPrice: {
    fontSize: '18px',
    fontWeight: '500',
  },
  noteContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    paddingBottom: '96px',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  icon: {
    fontSize: '16px',
    marginRight: '5px',
  },
  text: {
    flex: '1',
    fontSize: '16px',
    fontWeight: '500',
  },
  note: {
    width: '100%',
    height: '90px',
    borderRadius: '6px',
    border: '1px solid rgba(149, 149, 149, 0.4)',
    padding: '10px',
    fontSize: '16px',
    fontWeight: '400',
    resize: 'none',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  actions: {
    width: '100%',
    height: '83px',
    padding: '1rem',
    borderTop: '1px solid rgba(149, 149, 149, 0.4)',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  amountContainer: {
    width: '120px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '1rem',
  },
  amount: {
    fontSize: '18px',
    fontWeight: '500',
  },
  confirmContainer: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
  },
  confirmButton: {
    width: '100%',
    height: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#F97F11',
    '&:hover': {
      backgroundColor: '#F97F11',
    },
    color: '#FFF',
    borderRadius: '.5rem',
    padding: '0 1rem',
  },
});

export default useStyles;
