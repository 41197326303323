import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import Home from '../pages/Main';
import Detail from '../pages/Detail';
import Items from '../pages/Items';
import Item from '../pages/_Detail';
import Order from '../pages/_Order';
import Sign from '../pages/Sign';
import Menu from '../pages/_Menu';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Home} isPrivate />
      <Route path="/detail" exact component={Detail} isPrivate />
      <Route path="/menu" exact component={Menu} isPrivate />
      <Route path="/items" exact component={Items} isPrivate />
      <Route path="/item" exact component={Item} isPrivate />
      <Route path="/order" exact component={Order} isPrivate />
      <Route path="/sign" exact component={Sign} />
    </Switch>
  );
}
