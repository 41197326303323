import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '70px',
    padding: '0 1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    background: '#011133',
    color: '#fff',

    '& span': {
      margin: 0,
      padding: 0,
      fontWeight: 500,
      textTransform: 'uppercase',
      fontSize: '1rem',
    },
  },
});

export default useStyles;
