export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const formatDescription = text => {
  if (text && text.length >= 151) {
    return `${text.substring(0, 150)}...`;
  }

  return text;
};
