import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import SwipeableViews from 'react-swipeable-views';
import HomeIcon from '@material-ui/icons/Home';
import CartIcon from '@material-ui/icons/ShoppingCart';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import Badge from '@material-ui/core/Badge';

import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import Body from '../Home/Body';
import Header from '../Home/Header';
import Menu from '../../components/Menu';
import Loading from '../../components/Loading';
import Categories from '../Categories';
import Search from '../Search';
import Cart from '../Cart';
import {
  changeTabIndex,
  comandasRequest,
} from '../../store/modules/data/actions';
import Item from '../../components/Item';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
      style={{}}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

function TabIcon(props) {
  const { total } = props;
  return (
    <Badge badgeContent={total} color="secondary">
      <CartIcon />
    </Badge>
  );
}

function Main() {
  document
    .querySelector('meta[name="theme-color"]')
    .setAttribute('content', '#011133');

  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { loading, tabIndex } = useSelector(state => state.data);
  const {
    loading: sendOrderLoading,
    order: { items = [] },
  } = useSelector(state => state.order);

  const handleChange = (_, newValue) => {
    dispatch(changeTabIndex(newValue));
  };

  const handleChangeIndex = index => {
    dispatch(changeTabIndex(index));
  };

  useEffect(() => {
    if (tabIndex === 0) {
      dispatch(comandasRequest());
    }
  }, [dispatch, tabIndex]);

  return (
    <div className={classes.root}>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabIndex}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={tabIndex} index={0}>
          <Header />
          <Body />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <Categories />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <Search />
        </TabPanel>
        <TabPanel value={tabIndex} index={3}>
          <Cart />
        </TabPanel>
      </SwipeableViews>
      <AppBar position="static" style={{ backgroundColor: '#011133' }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons="off"
        >
          <Tab icon={<HomeIcon />} {...a11yProps(0)} />
          <Tab icon={<MenuIcon />} {...a11yProps(1)} />
          <Tab icon={<SearchIcon />} {...a11yProps(2)} />
          <Tab icon={<TabIcon total={items.length} />} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <Menu />
      <Loading loading={loading} />
      <Loading loading={sendOrderLoading} />
      <Item />
    </div>
  );
}

export default Main;
