import React, { useEffect, useState } from 'react';

import { formatDescription, formatPrice } from '../../../utils/format';
import Item from './Item';

import useStyles from './styles';

function Category({ category, handleClickItem }) {
  const classes = useStyles();

  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (category) {
      setProducts([
        ...category.products.map(product => {
          return {
            ...product,
            description: formatDescription(product.description),
            descriptionFull: product.description,
            formattedPrice: formatPrice(product.price),
          };
        }),
      ]);
    }
  }, [category]);

  return (
    <div
      id={`category-${category.category_id}`}
      data-category={category.category_id}
      className={classes.root}
    >
      <span className={classes.title}>{category.name.toLowerCase()}</span>
      {products.map(item => (
        <Item
          key={item.product_id}
          item={item}
          handleClickItem={handleClickItem}
        />
      ))}
    </div>
  );
}

export default Category;
