export function selected(product) {
  return {
    type: '@item/SELECTED',
    payload: { product },
  };
}

export function complementAdd(product) {
  return {
    type: '@item/COMPLEMENT_ADD',
    payload: { product },
  };
}

export function complementRemove(product) {
  return {
    type: '@item/COMPLEMENT_REMOVE',
    payload: { product },
  };
}

export function changeAmount(amount) {
  return {
    type: '@item/CHANGE_AMOUNT',
    payload: { amount },
  };
}

export function setComment(comment) {
  return {
    type: '@item/SET_COMMENT',
    payload: { comment },
  };
}

export function unsetComment() {
  return { type: '@item/UNSET_COMMENT' };
}

export function close() {
  return { type: '@item/CLOSE' };
}
