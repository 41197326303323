import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '../../../services/history';
import { login } from '../../../services/api';

import { signSuccess, signFailure, starting } from './actions';
import { decodedServer } from '../data/actions';

function* signIn({ payload }) {
  try {
    const { id, password } = payload;
    const response = yield call(login, { id, password });
    const { company, categories, cards, user, users } = response;
    if (company) {
      const { server: ip, port } = company;
      yield put(decodedServer({ ip, port }));
      yield put(signSuccess(company, categories, cards, user, users));
      history.push('/');
    } else {
      yield put(signFailure());
      toast.error('Falha, id ou senha incorretos');
    }
  } catch (err) {
    yield put(signFailure());
    toast.error('Houve um erro inesperado. Tente novamente');
  }
}

function signOut() {
  localStorage.removeItem('categories');
  localStorage.removeItem('produtos');
  history.push('/');
}

function* start() {
  yield put(starting());
}

export default all([
  takeLatest('@auth/SIGN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('persist/REHYDRATE', start),
]);
