import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  reload: false,
  categories: [],
  category: null,
  items: [],
  comandas: [],
  comanda: null,
  server: null,
  tabIndex: 0,
  search: '',
};

export default function data(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@data/DECODED_SUCCESS': {
        const { server } = action.payload;
        draft.server = { ...server };
        localStorage.setItem('server', JSON.stringify(server));
        break;
      }

      case '@data/COMANDAS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@data/COMANDAS_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@data/COMANDAS_SUCCESS': {
        const { comandas } = action.payload;
        draft.comandas = [...comandas];
        draft.loading = false;
        draft.reload = false;
        break;
      }

      case '@data/COMANDA_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@data/COMANDA_ITEMS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@data/COMANDA_ORDERS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@data/COMANDA_AUDITS_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@data/COMANDA_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@data/COMANDA_SUCCESS': {
        const { comanda } = action.payload;
        draft.comanda = { ...comanda };
        draft.loading = false;
        break;
      }

      case '@data/CATEGORIES_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@data/CATEGORIES_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@data/CATEGORIES_SUCCESS': {
        const { categories } = action.payload;
        draft.categories = [...categories];
        draft.loading = false;
        break;
      }

      case '@data/ITEMS_REQUEST': {
        const { category = null } = action.payload;
        if (category) {
          draft.category = { ...category };
          draft.loading = true;
        }
        break;
      }

      case '@data/ITEMS_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@data/ITEMS_SUCCESS': {
        const { items } = action.payload;
        draft.items = [...items];
        draft.loading = false;
        break;
      }

      case '@data/CLOSE_COMANDA_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@data/CLOSE_COMANDA_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@data/CLOSE_COMANDA_SUCCESS': {
        const {
          parameters: { prevenda_id: comanda_id },
        } = action.payload;

        draft.comandas = [
          ...draft.comandas.map(comanda => {
            return {
              ...comanda,
              situacao:
                comanda.comanda_id === comanda_id ? 'F' : comanda.situacao,
            };
          }),
        ];
        draft.loading = false;
        break;
      }

      case '@data/REOPEN_COMANDA_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@data/REOPEN_COMANDA_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@data/REOPEN_COMANDA_SUCCESS': {
        const {
          parameters: { prevenda_id: comanda_id },
        } = action.payload;

        draft.comandas = [
          ...draft.comandas.map(comanda => {
            return {
              ...comanda,
              situacao:
                comanda.comanda_id === comanda_id ? 'A' : comanda.situacao,
            };
          }),
        ];
        draft.loading = false;
        break;
      }

      case '@data/CANCEL_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@data/CANCEL_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@data/CANCEL_SUCCESS': {
        draft.reload = true;
        break;
      }

      case '@data/CANCEL_ITEM_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@data/CANCEL_ITEM_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@data/CANCEL_ITEM_SUCCESS': {
        draft.comanda = {
          ...draft.comanda,
          resumo: null,
        };
        break;
      }

      case '@data/TRANSFER_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@data/TRANSFER_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@data/TRANSFER_SUCCESS': {
        draft.reload = true;
        break;
      }

      case '@data/TRANSFER_ITEM_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@data/TRANSFER_ITEM_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@data/TRANSFER_ITEM_SUCCESS': {
        draft.comanda = {
          ...draft.comanda,
          resumo: null,
        };
        break;
      }

      case '@data/CHANGE_TAB_INDEX': {
        const { index } = action.payload;
        draft.tabIndex = index;
        break;
      }

      case '@data/CHANGE_SEARCH': {
        const { search } = action.payload;
        draft.search = search;
        break;
      }

      case '@starting': {
        draft.loading = false;
        draft.tabIndex = 0;
        draft.search = '';
        break;
      }

      default:
    }
  });
}
